/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import useEmailPhoneAutocomplete from '../../hooks/useEmailPhoneAutocomplete';
import * as AppPropTypes from '../../lib/PropTypes';

import PhoneField from './PhoneField';
import TextField from './TextField';

import styles from '../../styles/fields/email-phone-field.module.css';

const propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    withoutAutocomplete: PropTypes.bool,
    onTypeChange: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: AppPropTypes.ref,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

const defaultProps = {
    type: null,
    value: null,
    placeholder: null,
    required: false,
    withoutAutocomplete: false,
    onTypeChange: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    inputRef: null,
    className: null,
    inputClassName: null,
};

function EmailPhoneField({
    type,
    value: initialValue,
    placeholder,
    required,
    withoutAutocomplete,
    onTypeChange,
    onChange,
    onFocus,
    onBlur,
    inputRef,
    className,
    inputClassName,
    ...props
}) {
    const onAutocompleteChange = useCallback(
        (newValue) => {
            if (onChange !== null) {
                const finalValue = newValue || null;
                onChange(finalValue);
            }
        },
        [onChange],
    );

    const {
        ref,
        value,
        suggestion = null,
        placeholder: customPlaceholder,
        onChange: onInputChange,
        setValue,
        type: fieldType = null,
    } = useEmailPhoneAutocomplete({
        value: initialValue,
        autocomplete: !withoutAutocomplete,
        onChange: onAutocompleteChange,
        onTypeChange,
        type,
        defaultType: 'email',
    });

    useEffect(() => {
        if (initialValue === null) {
            setValue(initialValue);
        }
    }, [initialValue]);

    const finalPlaceholder =
        !withoutAutocomplete && !isEmpty(value) ? suggestion || customPlaceholder : placeholder;

    const refFieldType = useRef(fieldType);

    useEffect(() => {
        if (ref.current !== null && refFieldType.current !== fieldType) {
            ref.current.focus();
            refFieldType.current = fieldType;
        }
    }, [fieldType]);

    return (
        <>
            {fieldType === 'tel' ? (
                <PhoneField
                    ref={(newRef) => {
                        ref.current = newRef;
                        if (inputRef !== null) {
                            inputRef.current = newRef;
                        }
                    }}
                    placeholder={finalPlaceholder}
                    value={value}
                    required={required}
                    onChange={onInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={styles.phone}ç
                    inputClassName={classNames([styles.container, className])}
                    withBorder
                    {...props}
                />
            ) : (
                <TextField
                    ref={(newRef) => {
                        ref.current = newRef;
                        if (inputRef !== null) {
                            inputRef.current = newRef;
                        }
                    }}
                    type="email"
                    placeholder={finalPlaceholder}
                    value={value}
                    required={required}
                    onChange={onInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={classNames([styles.container, className])}
                    withBorder
                    {...props}
                />
            )}
        </>
    );
}

EmailPhoneField.propTypes = propTypes;
EmailPhoneField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <EmailPhoneField {...props} inputRef={ref} />);
